.landHeader{
    width: 60%;
    top: 5%;
    background-color: #fff;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50px;
    box-shadow: 0px 0px 10px #ff4d0024;
    z-index: 1111;
}

@media (max-width: 1200px) {
    .landHeader{
        width: 80%;
    }
}

@media (max-width: 400px) {
    .landHeader{
        width: 100%;
        top: 0%;
        background-color: #fff;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 0px;
        box-shadow: 0px 0px 10px #ff4d0024;
        z-index: 1111;
    }
}