.firstSection{
    position: relative;
    overflow: hidden;
    background-color: transparent;
    background-image: linear-gradient(180deg, #63a1ce -36%, #F7F6FF 100%);
    height: 100%;
}
.whatoffer{
    background-color: transparent;
    background-image: linear-gradient(180deg, #63a1ce -36%, #F7F6FF 100%);
    padding: 30px;
    border-radius: 20px;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
} 
.whatoffer1{
    padding: 0px 50px;
    border-radius: 20px;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
} 
.heading1{
    position: relative;
    display: inline-block;
    font-weight: 700;
}  
.heading1::after{
    content: '';
    display: block;
    position: absolute;
    background-color: #0b4c7c;
    left: 0;
    bottom: -10px;
    width: 100%;
    height: 2px;
}
.headText{
    position: relative;
}
.headText::after{
    content: url(../../assets/images/landSec.png);
    position: absolute;
    right: 230px;
    bottom: -30px;
}
.secondSec{
    padding: 50px 0px;
    overflow: hidden;
}
.gradCircle3{
    width: 500px;
    height: 500px;
    background-color: #e2dbff;
    border-radius: 50%;
    position: absolute;
    right: -10%;
    top: 30%;
    filter: blur(60px);
}
.thirdSec{
    padding: 20px 20px;
    overflow: hidden;
}
.thirdSec.bg{
    padding: 70px 20px;
    overflow: hidden;
    background-color: #bababa;
}
.fourthSec{
    padding: 100px 0px 0px 0px;
    overflow: hidden;
}
.lastSection{
    padding: 100px 0px;
    overflow-x: hidden; 
    position: relative;
}
.gradCircle5{
    width: 500px;
    height: 500px;
    background-color: #e2dbff;
    border-radius: 50%;
    position: absolute;
    right: -20%;
    top: 10%;
    filter: blur(60px);
}
.gradText{
    background: linear-gradient(to right, #FFA800, #FF71A4, #933EFF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
